import { Box, Tab, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { ReportingIcon } from '../Icons/ReportingIcon';
import { ResourcesIcon } from '../Icons/ResourcesIcon';
import { UsersIcon } from '../Icons/UsersIcon';

import './NavigationPanel.scss';
import {
  CopilotContext,
  ICopilotContext,
  CopilotTabIcon,
} from 'hive-copilot-react';
import { useContext } from 'react';

export function NavigationTabs({
  orientation = 'vertical',
}: {
  orientation?: 'vertical' | 'horizontal';
}) {
  const { hasUserRole: hasCopilotUserRole } =
    useContext<ICopilotContext>(CopilotContext);
  const { t } = useTranslation();
  const location = useLocation();
  return (
    <Tabs
      value={location.pathname}
      orientation={orientation}
      variant="scrollable"
    >
      <Tab
        value="/"
        to="/"
        component={Link}
        icon={orientation === 'vertical' ? <ReportingIcon /> : ''}
        iconPosition="start"
        label={t('reports.form.title')}
      />
      <Tab
        value="/email-report"
        to="/email-report"
        component={Link}
        icon={orientation === 'vertical' ? <ResourcesIcon /> : ''}
        iconPosition="start"
        label={t('email_reports.title')}
      />
      <Tab
        value="/user-management"
        to="/user-management"
        component={Link}
        icon={orientation === 'vertical' ? <UsersIcon /> : ''}
        iconPosition="start"
        label={t('user.management.title')}
      />
      {(hasCopilotUserRole === undefined || !!hasCopilotUserRole) && (
        <Tab
          value="/chat"
          to="/chat"
          component={Link}
          icon={orientation === 'vertical' ? <CopilotTabIcon /> : ''}
          iconPosition="start"
          label={t('chat.tab')}
        />
      )}
    </Tabs>
  );
}

export function NavigationPanel() {
  return (
    <Box component="nav" className="side-nav" flexShrink={0}>
      <NavigationTabs />
    </Box>
  );
}
