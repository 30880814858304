import {
  createTheme,
  CssBaseline,
  Stack,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material';
import { useTitleEffect } from 'hive-react-utils';
import { useEffect } from 'react';
import { DataContextProvider } from './contexts/DataContext';
import Main from './Main';
import { theme as baseTheme } from './theme';
import {
  copilotThemeOptions,
  CopilotContextProvider,
} from 'hive-copilot-react';

import buildNumber from './build-number';

const theme = createTheme({ ...baseTheme }, copilotThemeOptions);

export default function App() {
  useTitleEffect('app.title');
  useEffect(() => console.log(`Network App version: ${buildNumber}`), []);

  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <CssBaseline />
        <Stack height="100%">
          <DataContextProvider>
            <CopilotContextProvider
              api={{
                actionSummarize: 'copilot_prompt.summarize',
                actionPrompt: 'copilot_prompt.prompt',
                actionExplainSql: 'copilot_prompt.explainSql',
              }}
              userRole="CopilotUser"
              devUserRole="CopilotDev"
            >
              <Main />
            </CopilotContextProvider>
          </DataContextProvider>
        </Stack>
      </StyledEngineProvider>
    </ThemeProvider>
  );
}
