import { ComponentsOverrides } from '@mui/material';
import { COLOURS } from '../constants/constants';
import { spacing } from './constants';

const styleOverrides: ComponentsOverrides['MuiTab'] = {
  root: {
    'header div.header-tabs &': {
      minHeight: 'unset',
    },
    'nav &': {
      backgroundColor: COLOURS.white,
      borderRadius: '8px',
      border: '1px solid #c9d0db',
      margin: spacing * 0.5,
      justifyContent: 'left',
      '&.Mui-selected': {
        backgroundColor: COLOURS.blue.medium,
        color: COLOURS.white,
        icon: {
          color: COLOURS.white,
        },
      },
    },
  },
};

export const MuiTab = {
  styleOverrides,
};

const tabsStyleOverrides: ComponentsOverrides['MuiTabs'] = {
  root: {
    maxWidth: 'unset',
    minHeight: 'unset',
  },
  indicator: {
    display: 'none',
  },
};

export const MuiTabs = {
  styleOverrides: tabsStyleOverrides,
};
