import { Container, Stack, styled, useMediaQuery } from '@mui/material';
import { ReactNode, memo } from 'react';
import { NavigationPanel } from '../components/NavigationPanel/NavigationPanel';
import { ReportDateRangePickerContextProvider } from '../hooks/useDateRangeContext';
import { DownloadReportFormContextProvider } from '../hooks/useDownloadReportContext';
import { theme } from '../theme';

export interface SettingsLayoutProps {
  className?: string;
  buttons?: JSX.Element;
  center?: JSX.Element;
  children?: JSX.Element | ReactNode;
}

const ContentContainer = styled(Container)(() => ({
  height: '100%',
  backgroundColor: 'white',
}));

const ContentLayout = memo<SettingsLayoutProps>(({ className, children }) => {
  const large = useMediaQuery(theme.breakpoints.up('xl'));
  return (
    <Stack className={className} direction="row" height="100%">
      {large && <NavigationPanel />}
      <ContentContainer>{children}</ContentContainer>
    </Stack>
  );
});

ContentLayout.displayName = 'ContentLayout';

const Content = memo(
  ({
    className,
    mainContent,
  }: {
    className?: string;
    mainContent: JSX.Element;
  }) => {
    return (
      <ContentLayout className={className}>
        <DownloadReportFormContextProvider>
          <ReportDateRangePickerContextProvider>
            {mainContent}
          </ReportDateRangePickerContextProvider>
        </DownloadReportFormContextProvider>
      </ContentLayout>
    );
  }
);

Content.displayName = 'Content';

export default Content;
