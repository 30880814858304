import { useContext } from 'react';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';
import ContentByStateFactory from './components/ContentByStateFactory';
import RouteElement from './components/Layout/RouteLayout';
import LoginAdmitter, {
  SCREEN_OIDC_DONE,
} from './components/LoginAdmitter/LoginAdmitter';
import { SignInWithOidc } from './components/SignInWithOidc/SignInWithOidc';
import { DownloadReportPage } from './pages/DownloadReportPage';
import { EmailReportPage } from './pages/EmailReportPage';
import { UserManagementPage } from './pages/UserManagementPage';
import { Oidc } from './components/LoginAdmitter/oidc';
import {
  CopilotChat,
  CopilotContext,
  ICopilotContext,
} from 'hive-copilot-react';

const USE_OIDC = (window as any)?._env_?.REACT_APP_USE_OIDC === 'true';

export default function Main(): JSX.Element {
  const { hasUserRole: hasCopilotUserRole } =
    useContext<ICopilotContext>(CopilotContext);
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Routes>
        {/* Redirect legacy loginAdmitter to login */}
        {!USE_OIDC && (
          <Route
            path="/loginAdmitter"
            element={
              <RouteElement>
                <Navigate to="/login/" replace />
              </RouteElement>
            }
          />
        )}

        {!USE_OIDC && (
          <Route
            path="/login"
            element={
              <RouteElement>
                <LoginAdmitter />
              </RouteElement>
            }
          />
        )}

        {USE_OIDC && (
          <Route
            path="/login"
            element={
              <RouteElement>
                <Oidc />
              </RouteElement>
            }
          />
        )}
        {USE_OIDC && (
          <Route
            path={SCREEN_OIDC_DONE}
            element={
              <RouteElement>
                <SignInWithOidc />
              </RouteElement>
            }
          />
        )}
        <Route
          path="/"
          element={
            <RouteElement>
              <ContentByStateFactory mainContent={<DownloadReportPage />} />
            </RouteElement>
          }
        />

        <Route
          path="/email-report"
          element={
            <RouteElement>
              <ContentByStateFactory mainContent={<EmailReportPage />} />
            </RouteElement>
          }
        />

        <Route
          path="/user-management"
          element={
            <RouteElement>
              <ContentByStateFactory mainContent={<UserManagementPage />} />
            </RouteElement>
          }
        />

        {(hasCopilotUserRole === undefined || !!hasCopilotUserRole) && (
          <Route
            path="/chat"
            element={
              <RouteElement>
                <ContentByStateFactory
                  className="hive-data-copilot"
                  mainContent={<CopilotChat />}
                />
              </RouteElement>
            }
          />
        )}

        <Route
          path="*"
          element={
            <RouteElement>
              <Navigate to="/" replace />
            </RouteElement>
          }
        />
      </Routes>
    </Router>
  );
}
